import {
  ComputedRef,
  isRef,
  Ref,
  useMeta,
  watch,
} from '@nuxtjs/composition-api'
import { toValue } from '@vueuse/core'
import { useI18n } from '~/src/composables/useI18n'

type MaybeRef<T> = Ref<T> | ComputedRef<T> | T

export const useTitle = function (title?: MaybeRef<string>, translate = true) {
  const domParser = new DOMParser()
  const { title: titleMeta, meta } = useMeta()
  const translator = useI18n()

  function parsePageTitle(title?: string, translate = true) {
    let parsedTitle = title?.trim()

    if (parsedTitle === undefined || parsedTitle.length === 0) {
      return undefined
    }

    if (translate) {
      parsedTitle = translator.t(parsedTitle) as string
    }

    return domParser.parseFromString(parsedTitle, 'text/html').documentElement
      .textContent as string
  }

  function setTitle(title?: string, translate = true) {
    const portalTitle = translator.t('common.title') as string
    const pageTitle = parsePageTitle(title, translate)

    titleMeta.value = `${pageTitle} – ${portalTitle}`

    meta.value.push({
      hid: 'og:title',
      property: 'og:title',
      content: portalTitle,
    })

    meta.value.push({
      hid: 'og:description',
      property: 'og:description',
      content: pageTitle ?? '',
    })
  }

  setTitle(toValue(title), translate)

  if (isRef(title)) {
    watch(title, (value) => {
      setTitle(value, translate)
    })
  }

  return { title: titleMeta, setTitle }
}

export default useTitle
