export interface AddressConstructor {
  street: string
  houseNumber: string
  additionalAddress: string
  city: string
  zipcode: string
  country: string
}

export default class Address {
  public street: string
  public houseNumber: string
  public additionalAddress: string
  public city: string
  public zipcode: string
  public country: string

  constructor({
    street = '',
    houseNumber = '',
    additionalAddress = '',
    city = '',
    zipcode = '',
    country = '',
  }: Partial<AddressConstructor> = {}) {
    this.street = street
    this.houseNumber = houseNumber
    this.additionalAddress = additionalAddress
    this.city = city
    this.zipcode = zipcode
    this.country = country
  }
}
